.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 0 5%;
  .grid-block {
    grid-column: 1/4;
  }

   .ant-col  {
    max-width: 100%;
  }

  @media (max-width: 850px) {
    grid-template-columns: 1fr 1fr;
    .grid-block {
      grid-column: 1/3;
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    .grid-block {
      grid-column: 1/2;
    }
  }
}

.textarea-quill {
  background-color: white;
  // grid-column: span 2fr;
  .ql-editor {
    min-height: 200px;
  }
}
